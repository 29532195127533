/** @jsx jsx */
import { jsx } from "theme-ui"
import React from 'react'
import { GlobalStyles } from '../gatsby-plugin-theme-ui/GlobalStyles'
import { SEO } from "./SEO"

export const Layout = (props) => {
  const { children } = props

  return (
    <>
      <SEO />
      <GlobalStyles />
      <main>
        {children}
      </main>
    </>
  )
}
