/** @jsx jsx */
import { jsx } from "theme-ui"
import showdown from 'showdown';

const converter = new showdown.Converter()

export const RichText = (props) => {
  const { text, customStyle } = props
  const html = converter.makeHtml(text)

  return (
    <div dangerouslySetInnerHTML={{ __html: html }} sx={customStyle} />
  );
};
