import React from "react";
import { Helmet } from "react-helmet";
import meta from "../../content/general/meta.json";

export const SEO = () => {
  return (
    <Helmet>
      <title>{meta.title}</title>
      <html lang="nl" />
      <meta name="title" content={meta.title} />
      <meta name="description" content={meta.description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={meta.url} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={meta.image_preview} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={meta.url} />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.description} />
      <meta property="twitter:image" content={meta.image_preview} />
    </Helmet>
  );
};
