/** @jsx jsx */
import { jsx } from "theme-ui"
import { Global } from '@emotion/react'
import "../assets/fonts/typography.css"

export const GlobalStyles = (props) => (
  <Global
    styles={(theme) => ({
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        overflow: "hidden"
      },
      body: {
        margin: 0,
        fontFamily: "brother-1816",
        position: "relative",
        overflow: "hidden",
        "::before": {
          content: "''",
          position: "absolute",
          width: "200%",
          height: "200%",
          top: "-50%",
          left: "-50%",
          zIndex: "-1",
          backgroundImage: `linear-gradient(#858585 1px, transparent 1px), linear-gradient(to right, #858585 1px, ${theme.colors.background} 1px)`,
          backgroundSize: "80px 80px",
          transform: "rotate(-1deg)",
        }
      }
    })}
  />
)
