/** @jsx jsx */
import { jsx } from "theme-ui"
import landingpage from "../../content/pages/landingpage.json"
import contact from "../../content/general/contact.json"
import { RichText } from "../components/RichText"
import { Layout } from "../components/Layout"
import svgLogo from "../assets/svg/logo.svg"

export default function Home() {
  return (
    <Layout>
      <div sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh"
      }}>
        <div sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bg: "primary",
          width: "100%",
          maxWidth: 1280,
          borderTopLeftRadius: "50vw",
          borderTopRightRadius: "50vw",
        }}>
          <div sx={{ mt: "8vh", mb: "4vh", width: ["50vw", 256, 320] }}>
            <img src={svgLogo} alt="" width="100%" />
          </div>
          <p sx={{
            fontSize: [14, 20, 22],
            width: "100%",
            maxWidth: 700,
            textAlign: "center",
            px: 5
          }}>
            {landingpage.intro}
          </p>
          <div sx={{ mt: "5vh" }}>
            <RichText text={landingpage.main_text}
              customStyle={{
                textAlign: "center",
                fontFamily: "hatton",
                "h1": {
                  fontSize: [28, 48, 54],
                  fontWeight: "normal",
                  m: 0
                },
                "a": {
                  fontWeight: "bold",
                  color: "text",
                  position: "relative",
                  display: "inline-block",
                  textDecoration: "none",
                  "::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    backgroundColor: "text",
                    width: "100%",
                    height: [1, 2],
                    bottom: 0,
                    transition: "bottom 200ms ease-in-out"
                  },
                  ":hover::before": {
                    bottom: 1
                  }
                }
              }}
            />
          </div>
          <div sx={{
            mt: "auto",
            mb: [3, 4],
            position: "relative",
            width: "100%"
          }}>
            <footer sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: [10, 14]
            }}>
              <a href={`https://www.google.com/maps/search/${contact.address_short}`} target="_blank" rel="noopener" sx={{
                color: "text",
                textDecoration: "none",
                ":hover": {
                  textDecoration: "underline"
                }
              }}>
                <address sx={{ fontStyle: "normal" }}>
                  {contact.address_short}
                </address>
              </a>
              <span sx={{ mx: 1 }}>-</span>

              <a href={`tel:${contact.phone}`} target="_blank" rel="noopener" sx={{
                color: "text",
                textDecoration: "none",
                ":hover": {
                  textDecoration: "underline"
                }
              }}><span>{contact.phone}</span></a>
              <span sx={{ mx: 1 }}>-</span>
              <span>{contact.opening_hours_short}</span>
            </footer>
            <a href="https://studiogrok.be/" target="_blank" rel="noopener" sx={{
              position: "absolute",
              transform: "rotate(-90deg)",
              transformOrigin: "bottom right",
              right: [16, 40],
              bottom: [110, 130],
              color: "text",
              textDecoration: "none",
              fontSize: [10, 12],
              ":hover": {
                textDecoration: "underline"
              }
            }}>Design by Studio Grok</a>
          </div>
        </div>
      </div>
    </Layout>
  )
}
